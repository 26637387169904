import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Link } from '@dx-ui/osc-link';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { useIsTailored } from '../../hooks/use-is-tailored-experience';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

type TContactUsProps = {
  email: string | null;
  networkDisclaimer?: string | null;
  phoneNumber: string | null;
};

type TArrivalTimeProps = {
  checkinInfo: NonNullable<GetHotelHomePageQuery['hotel']>['registration'];
};

export const ArrivalTime = ({ checkinInfo, wrapperClass }: TWithCustomTheme<TArrivalTimeProps>) => {
  const { t } = useTranslation('dx-utility-rail');
  const isTailored = useIsTailored();

  return (
    <div>
      <div
        className={cx(
          'mb-0 text-sm font-bold capitalize md:mb-3',
          {
            'text-text-alt': !isTailored,
            'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
          },
          wrapperClass
        )}
      >
        {t('arrivalTime')}
      </div>
      <div className="flex flex-row items-start">
        <div className={cx('whitespace-nowrap text-center', wrapperClass)}>
          <span className="mb-1 text-center text-base">{t('checkIn')}</span>
          <span
            data-testid="tab-check-in-time"
            className={cx(
              'ml-1 text-center text-base',
              {
                'font-bold text-text-alt': !isTailored,
                'font-normal': isTailored,
              },
              wrapperClass
            )}
          >
            {checkinInfo?.checkinTimeFmt}
          </span>
          <span className="mx-4 mb-1 text-center text-base">&#8594;</span>
        </div>
        <div className={cx('whitespace-nowrap text-center', wrapperClass)}>
          <span className="text-center text-base">{t('checkOut')}</span>
          <span
            data-testid="tab-check-out-time"
            className={cx('ml-1 text-center text-base', {
              'font-bold text-text-alt': !isTailored,
              'font-normal': isTailored,
            })}
          >
            {checkinInfo?.checkoutTimeFmt}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ContactUs = ({
  email,
  networkDisclaimer,
  phoneNumber,
  wrapperClass,
}: TWithCustomTheme<TContactUsProps>) => {
  const { t } = useTranslation('dx-utility-rail');
  const isTailored = useIsTailored();

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3 px-3 sm:flex-row sm:justify-between sm:px-0">
        <div className="sm:flex sm:flex-col sm:gap-3">
          <p
            aria-hidden
            className={cx(
              'text-sm font-bold capitalize',
              {
                'text-text-alt': !isTailored,
                'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
              },
              wrapperClass
            )}
          >
            {t('call')}
          </p>
          <p className="sr-only">{t('call')}</p>
          <Link
            className={cx(
              'whitespace-nowrap text-base',
              {
                'text-text hover:text-primary-alt': !isTailored,
                'brand-lx:font-normal brand-ou:font-normal': isTailored,
              },
              wrapperClass
            )}
            role="link"
            adaDescription={`Phone: ${phoneNumber}`}
            label={phoneNumber || ''}
            url={`tel:${phoneNumber}`}
          >
            {phoneNumber}
          </Link>
        </div>
        {networkDisclaimer ? <p className="text-sm sm:hidden">{networkDisclaimer}</p> : null}
        {email ? (
          <div className="sm:flex sm:flex-col sm:gap-3">
            <p
              aria-hidden
              className={cx(
                'text-sm font-bold capitalize',
                {
                  'text-text-alt': !isTailored,
                  'brand-ou:uppercase brand-ou:font-normal brand-ou:font-mono': isTailored,
                },
                wrapperClass
              )}
            >
              {t('email')}
            </p>
            <p className="sr-only">{t('email')}</p>
            <a
              aria-label={email}
              className={cx(
                'flex flex-row text-base underline',
                {
                  'flex-col xl:flex-row': email.split('@')[0].length >= 20,
                  'text-text hover:text-primary-alt': !isTailored,
                },
                wrapperClass
              )}
              href={`mailto:${email}`}
            >
              <span>{email.split('@')[0]}</span>
              <span>{`@${email.split('@')[1]}`}</span>
            </a>
          </div>
        ) : null}
      </div>
      {networkDisclaimer ? (
        <p className="hidden text-sm sm:block sm:max-w-80" data-testid="networkDisclaimer">
          {networkDisclaimer}
        </p>
      ) : null}
    </div>
  );
};

export const FlexibleDivider = () => {
  const isTailored = useIsTailored();
  return (
    <div className="mx-4 flex justify-center font-normal" data-testid="utility-rail-divider">
      <div
        className={cx('border-r border-solid', {
          'border-border-alt': !isTailored,
          'border-text': isTailored,
        })}
      />
    </div>
  );
};
