import cx from 'classnames';
import type { IconNames } from '@dx-ui/osc-icon';
import Icon from '@dx-ui/osc-icon';
import { useTranslation } from 'react-i18next';

export type PresetChipTypes = 'featured' | 'new' | 'sale' | 'package';

export type ChipProps = {
  className?: string;
  isIconTrailing?: boolean;
  label?: string;
  preset?: PresetChipTypes;
  variant?: 'honors' | 'general' | 'marketing' | 'neutral';
} & (
  | {
      size?: 'md' | 'lg';
      iconName?: (typeof IconNames)[number] | 'checkmark';
    }
  | {
      size?: 'sm';
      iconName?: 'checkmark';
    }
);

const presets: PresetChipTypes[] = ['featured', 'new', 'sale'];

export const presetFilter = (preset: string) => (presets as readonly string[]).includes(preset);

export function Chip({
  className,
  iconName,
  isIconTrailing = false,
  size = 'md',
  label,
  preset,
  variant,
  ...rest
}: ChipProps) {
  const { t } = useTranslation('osc-chip');
  const chipLabel = preset ? t(preset) : label;
  const VARIANT_STYLES = Object.freeze({
    honors: 'border-tier-diamond text-tier-diamond',
    general: 'border-text text-text',
    marketing: 'border-success text-success',
    neutral: 'border-text-inverse text-text-inverse',
  });
  const PRESET_STYLES = Object.freeze({
    featured: VARIANT_STYLES.marketing,
    new: VARIANT_STYLES.general,
    sale: VARIANT_STYLES.marketing,
    package: VARIANT_STYLES.general,
  });
  const variantClassName = variant && VARIANT_STYLES[variant];
  const presetClassName = preset && PRESET_STYLES[preset];

  const icon = () => {
    if (iconName && iconName !== 'checkmark') {
      return (
        <div data-testid="osc-chip-icon">
          <Icon name={iconName} size="xs" variant="regular" />
        </div>
      );
    }

    if (iconName === 'checkmark') {
      return (
        <div data-testid="osc-chip-checkmark-icon">
          <Icon name="check" size={size === 'sm' ? 'xs' : 'sm'} />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      data-testid={preset ? `osc-${preset}-chip` : 'osc-chip'}
      {...rest}
      className={cx(
        'inline-flex items-center gap-x-1 rounded-full border font-["Proxima_Nova"] normal-case not-italic',
        {
          'px-2 px-py text-xs font-semibold': size === 'sm',
          'px-3 py-1 text-xs font-semibold': size === 'md',
          'px-3 py-2 text-xs font-bold': size === 'lg',
          'border-text text-text': !presetClassName && !variantClassName,
          [presetClassName as string]: !!presetClassName && !variantClassName,
        },
        variantClassName,
        className
      )}
    >
      {!isIconTrailing && icon()}
      {chipLabel}
      {isIconTrailing && icon()}
    </div>
  );
}

export default Chip;
