import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { Chip } from '@dx-ui/osc-chip';

import { useNewHotelChipTest } from '../../hooks/useNewHotelChipTest';
import { useRenovationFlagTest } from '../../hooks/useRenovationFlagTest';

const HotelChip = ({ ctyhocn, className }: { ctyhocn: string; className?: string }) => {
  const { showRenovationChipInHeader, showRenovationChipInUtilityRail } =
    useRenovationFlagTest(ctyhocn);
  const { showNewHotelChip } = useNewHotelChipTest();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { t } = useTranslation('common');

  const recentlyRenovatedLabel = showRenovationChipInHeader || showRenovationChipInUtilityRail;

  if (!showNewHotelChip && !showRenovationChipInHeader && !showRenovationChipInUtilityRail)
    return null;

  return (
    <span className={className}>
      <Chip
        label={recentlyRenovatedLabel ? t('recentlyRenovated') : t('newHotel')}
        size={isDesktop ? 'lg' : 'sm'}
        className="text-nowrap"
      />
    </span>
  );
};

export default HotelChip;
