import cx from 'classnames';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { useIsTailored } from '../../hooks/use-is-tailored-experience';
import { useRenovationFlagTest } from '../../hooks/useRenovationFlagTest';
import { TripAdvisor } from '../trip-advisor/tripAdvisor';
import { ArrivalTime, ContactUs, FlexibleDivider } from './utility-rail-tab-panels';
import { UtilityRailTabbedSection } from './utility-rail-tabbed';
import HotelChip from '../layout/HotelChip';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

export type UtilityRailType = {
  contactInfo: NonNullable<GetHotelHomePageQuery['hotel']>['contactInfo'];
  ctyhocn: string;
  hotelName: NonNullable<GetHotelHomePageQuery['hotel']>['name'];
  registration: NonNullable<GetHotelHomePageQuery['hotel']>['registration'];
  tripAdvisorData: NonNullable<GetHotelHomePageQuery['hotel']>['tripAdvisorLocationSummary'];
};

const formatTimeForUI = (time: string): string =>
  time.replace(/AM/g, 'am').replace(/PM/g, 'pm').replace(/:00/g, '');

const UtilityRail = ({
  contactInfo,
  ctyhocn,
  hotelName,
  registration,
  tripAdvisorData,
  wrapperClass,
}: TWithCustomTheme<UtilityRailType>) => {
  const { showRenovationChipInUtilityRail } = useRenovationFlagTest(ctyhocn);

  const isTailored = useIsTailored();

  const showTripAdvisorWidget = Boolean(tripAdvisorData?.rating && tripAdvisorData?.rating >= 3.5);

  const checkinInfo = {
    ...registration,
    checkinTimeFmt: registration?.checkinTimeFmt && formatTimeForUI(registration.checkinTimeFmt),
    checkoutTimeFmt: registration?.checkoutTimeFmt && formatTimeForUI(registration.checkoutTimeFmt),
  } as NonNullable<GetHotelHomePageQuery['hotel']>['registration'];

  return (
    <section
      className={cx('pb-4 w-full', {
        'bg-bg border-border-alt border-b border-solid': isTailored,
        'bg-bg-alt': !isTailored,
      })}
      data-testid="utility-rail-sec"
    >
      <div
        className="container hidden justify-around py-6 lg:flex"
        data-testid="test-desktop-utility-rail"
      >
        {showRenovationChipInUtilityRail ? (
          <>
            <HotelChip className="flex items-start" ctyhocn={ctyhocn} />
            <FlexibleDivider />
          </>
        ) : null}
        {showTripAdvisorWidget ? (
          <>
            <TripAdvisor
              shouldDisplayInRow={isTailored}
              reviewData={tripAdvisorData}
              wrapperClass={wrapperClass}
            />
            <FlexibleDivider />
          </>
        ) : null}
        <ContactUs
          email={contactInfo?.marketingEmail || ''}
          networkDisclaimer={contactInfo?.networkDisclaimer ?? ''}
          phoneNumber={contactInfo?.phoneNumber || ''}
          wrapperClass={wrapperClass}
        />
        <FlexibleDivider />
        <ArrivalTime checkinInfo={checkinInfo} wrapperClass={wrapperClass} />
      </div>
      <UtilityRailTabbedSection
        checkinInfo={checkinInfo}
        contactInfo={contactInfo}
        ctyhocn={ctyhocn}
        hotelName={hotelName || ''}
        reviewData={tripAdvisorData}
        showTripAdvisorWidget={showTripAdvisorWidget}
        showRenovationChipInUtilityRail={showRenovationChipInUtilityRail}
        wrapperClass={wrapperClass}
      />
    </section>
  );
};

export default UtilityRail;
