import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

export const useNewHotelChipTest = () => {
  const { isLoaded, selection } = useConductricsSelection('a-Mt431r5RVd9e', Status.OK);

  const showNewHotelChip = isLoaded && selection?.choice === 'B';

  return {
    showNewHotelChip,
  };
};
